import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'About',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/streets',
    name: 'Streets',
    component: () => import('../views/StreetsView.vue'),
  },
  {
    path: '/portraits',
    name: 'Portraits',
    component: () => import('../views/PortraitsView.vue'),
  },
  {
    path: '/publications',
    name: 'Publications',
    component: () => import('../views/PublicationsView.vue'),
  },
  {
    path: '/license',
    name: 'License',
    component: () => import('../views/LicenseView.vue'),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
